/* Template from material-ui */
import React from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew'
import PeopleIcon from '@material-ui/icons/People'
import StarsIcon from '@material-ui/icons/Stars'

import useStyles from 'style'
import { Link } from '@material-ui/core'

const Factorio = () => {
  const classes = useStyles()

  return (
    <>
      {/* Hero unit */}
      <div className={classes.heroContent}>
        <Container maxWidth='md'>
          <Typography component='h1' variant='h2' align='center' color='textPrimary' gutterBottom>
            Factorio Session Timelapses
          </Typography>
          <Typography variant='h5' align='center' color='textSecondary' paragraph>
            Timelapses made by: <a href='https://mods.factorio.com/mod/L0laapk3_FactorioMaps'>FactorioMaps Timelapse Mod</a>
          </Typography>
        </Container>
      </div>
      <Container className={classes.cardGrid} maxWidth='md'>
        <Grid container spacing={4}>
          <Grid item key='seablocksolo' xs={12} sm={6} md={4}>
            <a href='/factorio/seablocksolo' className={classes.noTextDecoration}>
              <Card className={classes.card}>
                <CardMedia
                  className={classes.cardMedia}
                  image={require('assets/images/thumbnail/factorio-seablocksolo.png')}
                  title='seablocksolo'
                />
                <CardContent className={classes.cardContent}>
                  <Typography gutterBottom variant='h5' component='h2'>
                    My First Seablock
                    <AccessibilityNewIcon />
                  </Typography>
                  <Typography>
                    1st Seablock mod playthrough<br /><br />
                    <StarsIcon className={classes.iconWithText} />
                    <Link href='https://mods.factorio.com/mod/SeaBlock'>Seablock</Link>
                  </Typography>
                </CardContent>
              </Card>
            </a>
          </Grid>
          <Grid item key='seablock' xs={12} sm={6} md={4}>
            <a href='/factorio/seablock' className={classes.noTextDecoration}>
              <Card className={classes.card}>
                <CardMedia
                  className={classes.cardMedia}
                  image={require('assets/images/thumbnail/factorio-seablock.png')}
                  title='seablock'
                />
                <CardContent className={classes.cardContent}>
                  <Typography gutterBottom variant='h5' component='h2'>
                    Seablock Multiplayer #1
                    <PeopleIcon />
                  </Typography>
                  <Typography>
                    Seablock mod multiplayer with Bono<br />
                    1st playthrough<br /><br />
                    <StarsIcon className={classes.iconWithText} />
                    <Link href='https://mods.factorio.com/mod/SeaBlock'>Seablock</Link>
                  </Typography>
                </CardContent>
              </Card>
            </a>
          </Grid>
          <Grid item key='seablock2' xs={12} sm={6} md={4}>
            <a href='/factorio/seablock2' className={classes.noTextDecoration}>
              <Card className={classes.card}>
                <CardMedia
                  className={classes.cardMedia}
                  image={require('assets/images/thumbnail/factorio-seablock2.png')}
                  title='seablock2'
                />
                <CardContent className={classes.cardContent}>
                  <Typography gutterBottom variant='h5' component='h2'>
                    Seablock Multiplayer #2
                    <PeopleIcon />
                  </Typography>
                  <Typography>
                    Seablock mod multiplayer with Bono (Rocket launched!)<br />
                    2nd playthrough<br /><br />
                    <StarsIcon className={classes.iconWithText} />
                    <Link href='https://mods.factorio.com/mod/SeaBlock'>Seablock</Link>
                  </Typography>
                </CardContent>
              </Card>
            </a>
          </Grid>
          <Grid item key='industrial' xs={12} sm={6} md={4}>
            <a href='/factorio/industrial' className={classes.noTextDecoration}>
              <Card className={classes.card}>
                <CardMedia
                  className={classes.cardMedia}
                  image={require('assets/images/thumbnail/factorio-industrial.png')}
                  title='industrial'
                />
                <CardContent className={classes.cardContent}>
                  <Typography gutterBottom variant='h5' component='h2'>
                    Industrial Revolution
                    <AccessibilityNewIcon />
                  </Typography>
                  <Typography>
                    Rocket launched!<br /><br />
                    <StarsIcon className={classes.iconWithText} />
                    <Link href='https://mods.factorio.com/mod/IndustrialRevolution'>Industrial Revolution</Link>
                  </Typography>
                </CardContent>
              </Card>
            </a>
          </Grid>
          <Grid item key='megabase' xs={12} sm={6} md={4}>
            <a href='/factorio/megabase' className={classes.noTextDecoration}>
              <Card className={classes.card}>
                <CardMedia
                  className={classes.cardMedia}
                  image={require('assets/images/thumbnail/factorio-megabase.png')}
                  title='megabase'
                />
                <CardContent className={classes.cardContent}>
                  <Typography gutterBottom variant='h5' component='h2'>
                    Vanilla Megabase
                    <PeopleIcon />
                  </Typography>
                  <Typography>
                    1KSPM vanilla megabase with Bono
                  </Typography>
                </CardContent>
              </Card>
            </a>
          </Grid>
          <Grid item key='py' xs={12} sm={6} md={4}>
            <a href='/factorio/py' className={classes.noTextDecoration}>
              <Card className={classes.card}>
                <CardMedia
                  className={classes.cardMedia}
                  image={require('assets/images/thumbnail/factorio-py.png')}
                  title='py'
                />
                <CardContent className={classes.cardContent}>
                  <Typography gutterBottom variant='h5' component='h2'>
                    Pyanodons HighTech
                    <AccessibilityNewIcon />
                  </Typography>
                  <Typography>
                    Train grid based PySuite playthrough<br />
                    <StarsIcon className={classes.iconWithText} />
                    <Link href='https://mods.factorio.com/mod/pyhightech'>Pyanodons HighTech</Link><br />
                    <StarsIcon className={classes.iconWithText} />
                    <Link href='https://mods.factorio.com/mod/LogisticTrainNetwork'>Logistic Train Network</Link>
                  </Typography>
                </CardContent>
              </Card>
            </a>
          </Grid>
          <Grid item key='pyal' xs={12} sm={6} md={4}>
            <a href='/factorio/pyal' className={classes.noTextDecoration}>
              <Card className={classes.card}>
                <CardMedia
                  className={classes.cardMedia}
                  image={require('assets/images/thumbnail/factorio-pyal.png')}
                  title='pyal'
                />
                <CardContent className={classes.cardContent}>
                  <Typography gutterBottom variant='h5' component='h2'>
                    Pyanodons AlienLife #1
                    <AccessibilityNewIcon />
                  </Typography>
                  <Typography>
                    Full PySuite playthrough<br />
                    <StarsIcon className={classes.iconWithText} />
                    <Link href='https://mods.factorio.com/mod/pyalienlife'>Pyanodons AlienLife</Link><br />
                    <StarsIcon className={classes.iconWithText} />
                    <Link href='https://mods.factorio.com/mod/LogisticTrainNetwork'>Logistic Train Network</Link>
                  </Typography>
                </CardContent>
              </Card>
            </a>
          </Grid>
          <Grid item key='pyal2' xs={12} sm={6} md={4}>
            <a href='/factorio/pyal2' className={classes.noTextDecoration}>
              <Card className={classes.card}>
                <CardMedia
                  className={classes.cardMedia}
                  image={require('assets/images/thumbnail/factorio-pyal2.png')}
                  title='pyal2'
                />
                <CardContent className={classes.cardContent}>
                  <Typography gutterBottom variant='h5' component='h2'>
                    Pyanodons AlienLife #2
                    <AccessibilityNewIcon />
                  </Typography>
                  <Typography>
                    Full PySuite playthrough without LTN<br />
                    <StarsIcon className={classes.iconWithText} />
                    <Link href='https://mods.factorio.com/mod/pyalienlife'>Pyanodons AlienLife</Link><br />
                  </Typography>
                </CardContent>
              </Card>
            </a>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default Factorio
