/* Template from material-ui */
import React from 'react'
import Typography from '@material-ui/core/Typography'
import CopyrightIcon from '@material-ui/icons/Copyright'
import Link from '@material-ui/core/Link'

import useStyles from 'style'

const Copyright = () => {
  const copyLeftStyle = {
    transform: 'scaleX(-1)',
    width: 15,
    height: 15,
    marginLeft: 5,
    marginRight: 5,
    color: '#666666'
  }
  return (
    <Typography variant='body2' color='textSecondary' align='center'>
      SoonHo Seo Copyleft
      <CopyrightIcon style={copyLeftStyle} />
      {new Date().getFullYear()}
      .
    </Typography>
  )
}

const Footer = () => {
  const classes = useStyles()
  return (
    <>
      <footer className={classes.footer}>
        <Typography variant='h6' align='center' gutterBottom>
          Personal Sandbox of Sonowz
        </Typography>
        <Typography align='center' color='textSecondary'>
          <Link href='https://stats.uptimerobot.com/6pClU4iFRY' target='_blank' rel='noreferrer' variant='subtitle1' color='textSecondary' underline='always'>
            Status Page
          </Link>
        </Typography>
        <div style={{ height: 20 }} />
        <Typography variant='subtitle1' align='center' color='textSecondary' component='p'>
          본 사이트는 상업적 용도로 사용할 계획이 없음을 밝힙니다.
        </Typography>
        <Copyright />
      </footer>
    </>
  )
}

export default Footer
