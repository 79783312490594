/* code taken from https://usehooks.com/useHover/ */
import { useEffect, useState, useRef } from 'react'

export function useHover () {
  const [value, setValue] = useState(false)
  const ref = useRef(null)
  const node = ref.current
  const handleMouseOver = () => setValue(true)
  const handleMouseOut = () => setValue(false)
  useEffect(
    () => {
      if (node) {
        node.addEventListener('mouseover', handleMouseOver)
        node.addEventListener('mouseout', handleMouseOut)
        return () => {
          node.removeEventListener('mouseover', handleMouseOver)
          node.removeEventListener('mouseout', handleMouseOut)
        }
      }
    },
    [node] // Recall only if ref changes
  )
  return [ref, value]
}
