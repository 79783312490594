import React, { useState, useEffect } from 'react'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardMedia from '@material-ui/core/CardMedia'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Slider from '@material-ui/core/Slider'
import _ from 'lodash'

import useStyles from 'style'
import Shuffler from 'components/features/Shuffler'
import { useOAuth } from 'hooks/useOAuth'
import useLocalStorage from 'hooks/useLocalStorage'
import useBackendStorage from 'hooks/useBackendStorage'

import iRespect from 'assets/images/djmax/respect.png'
import iEmotinalsense from 'assets/images/djmax/emotionalsense.jpg'
import iVextension from 'assets/images/djmax/vextension.jpg'
import iVextension2 from 'assets/images/djmax/vextension2.jpg'
import iVextension3 from 'assets/images/djmax/vextension3.jpg'
import iVextension4 from 'assets/images/djmax/vextension4.jpg'
import iPortable from 'assets/images/djmax/portable.jpg'
import iPortable2 from 'assets/images/djmax/portable2.jpg'
import iPortable3 from 'assets/images/djmax/portable3.jpg'
import iClazziquai from 'assets/images/djmax/clazziquai.jpg'
import iBlacksquare from 'assets/images/djmax/blacksquare.jpg'
import iTrilogy from 'assets/images/djmax/trilogy.jpg'
import iTechnika from 'assets/images/djmax/technika.jpg'
import iTechnika2 from 'assets/images/djmax/technika2.jpg'
import iTechnika3 from 'assets/images/djmax/technika3.jpg'
import iTechnikatq from 'assets/images/djmax/technikatq.jpg'
import iGuiltygear from 'assets/images/djmax/guiltygear.jpg'
import iFrontline from 'assets/images/djmax/frontline.jpg'
import iGroovecoaster from 'assets/images/djmax/groovecoaster.jpg'
import iDeemo from 'assets/images/djmax/deemo.jpg'
import iCytus from 'assets/images/djmax/cytus.jpg'
import iChunithm from 'assets/images/djmax/chunithm.jpg'
import iEstimate from 'assets/images/djmax/estimate.jpg'
import iNexon from 'assets/images/djmax/nexon.jpg'
import iMusedash from 'assets/images/djmax/musedash.jpg'
import iEz2on from 'assets/images/djmax/ez2on.jpg'
import iMaplestory from 'assets/images/djmax/maplestory.jpg'

const DEFAULT_SHOWCOUNT = 5

const newPack = (image, text) => ({
  image: image,
  text: text
})

const packData = [
  newPack(iRespect, 'RESPECT'),
  newPack(iVextension, 'V EXTENSION'),
  newPack(iVextension2, 'V EXTENSION 2'),
  newPack(iVextension3, 'V EXTENSION 3'),
  newPack(iVextension4, 'V EXTENSION 4'),
  newPack(iEmotinalsense, 'EMOTIONAL SENSE'),
  newPack(iPortable, 'PORTABLE'),
  newPack(iPortable2, 'PORTABLE 2'),
  newPack(iPortable3, 'PORTABLE 3'),
  newPack(iClazziquai, 'CLAZZIQUAI EDITION'),
  newPack(iBlacksquare, 'BLACK SQUARE'),
  newPack(iTrilogy, 'TRILOGY'),
  newPack(iGuiltygear, 'GUILTY GEAR'),
  newPack(iGroovecoaster, 'GROOVE COASTER'),
  newPack(iDeemo, 'DEEMO'),
  newPack(iCytus, 'CYTUS'),
  newPack(iTechnika, 'TECHNIKA'),
  newPack(iTechnika2, 'TECHNIKA2'),
  newPack(iTechnika3, 'TECHNIKA3'),
  newPack(iTechnikatq, 'TECHNIKA TUNE & Q'),
  newPack(iFrontline, 'GIRLS\' FRONTLINE'),
  newPack(iChunithm, 'CHUNITHM'),
  newPack(iEstimate, 'ESTIMATE'),
  newPack(iNexon, 'NEXON'),
  newPack(iMusedash, 'MUSE DASH'),
  newPack(iEz2on, 'EZ2ON'),
  newPack(iMaplestory, 'MAPLESTORY')
]

const initPacks = _.map(_.zip(packData, _.range(packData.length)), ([pack, i]) => {
  pack.id = i
  return pack
})

// If check fails, local data gets reinitialized
const checkPacks = (packs, setPacks) => {
  const initPackDict = _.keyBy(initPacks, initPack => initPack.id)

  let invalidId = false
  let invalidData = false
  if (packs.length !== initPacks.length) {
    invalidId = true
  }
  _.each(packs, pack => {
    if (!pack || !initPackDict[pack.id]) {
      invalidId = true
      return
    }
    if (pack.image !== initPackDict[pack.id].image || pack.text !== initPackDict[pack.id].text) {
      invalidData = true
      // return
    }
  })

  if (invalidId) {
    setPacks(initPacks)
  } else if (invalidData) {
    const newPacks = _.compact(_.map(packs, pack => {
      if (!pack) return null
      pack.image = initPackDict[pack.id].image
      pack.text = initPackDict[pack.id].text
      return pack
    }))
    setPacks(newPacks)
  }
}

// https://stackoverflow.com/questions/2909367/can-you-determine-if-chrome-is-in-incognito-mode-via-a-script
const checkIncognito = async () => {
  const fs = window.RequestFileSystem || window.webkitRequestFileSystem
  if (!fs) return new Promise(true)
  return new Promise(resolve =>
    fs(window.TEMPORARY, 100, () => resolve(false), () => resolve(true))
  )
}

const DJMax = () => {
  const auth = useOAuth()
  const classes = useStyles()
  const [incognitoMode, setIncognitoMode] = useState(false)

  // If logged in, use & store data in backend server
  // Otherwise, use localStorage
  const [packsLocal, setPacksLocal] = useLocalStorage('djmax.packs', initPacks)
  const [showCountLocal, setShowCountLocal] = useLocalStorage('djmax.showCount', DEFAULT_SHOWCOUNT)
  const [packsBackend, setPacksBackend, packsInitDone] =
    useBackendStorage('djmax.packs', initPacks, auth.user)
  const [showCountBackend, setShowCountBackend, showCountInitDone] =
    useBackendStorage('djmax.showCount', DEFAULT_SHOWCOUNT, auth.user)
  const [packs, setPacks, showCount, setShowCount] = auth.user
    ? [packsBackend, setPacksBackend, showCountBackend, setShowCountBackend]
    : [packsLocal, setPacksLocal, showCountLocal, setShowCountLocal]
  const initDone = packsInitDone && showCountInitDone

  useEffect(() => {
    // 'async' workaround for useEffect
    const asyncFn = async () => setIncognitoMode(await checkIncognito())
    asyncFn()
  }, [])

  useEffect(() => {
    if (initDone) checkPacks(packs, setPacks)
  }, [packs, setPacks, initDone])

  // This shouldn't take a long time...
  // TODO: add spinner?
  if (!initDone) return null

  const topPacks = _.take(packs, showCount)

  return (
    <>
      {incognitoMode ? <Typography variant='h6' color='error'>시크릿 모드에서는 섞기 기록이 저장되지 않습니다.</Typography> : <></>}
      {!auth.user ? <Typography variant='h6' color='error'>게스트 모드 이용중입니다. 섞기 기록은 로컬에 저장됩니다.</Typography> : <></>}
      <Container className={classes.cardGrid} maxWidth='xl'>
        <Button variant='contained' color='primary' component='a' href='/djmaxps4'>
          PS4 버전 바로가기
        </Button>
        <div style={{ height: '10px' }} />
        {/* showCount slider */}
        <Box maxWidth={350} paddingBottom={5}>
          <Paper className={classes.paper}>
            <Box className={classes.horizontalBox} width={300}>
              <Typography>Showing&nbsp;&nbsp;&nbsp;</Typography>
              <Slider
                value={showCount}
                valueLabelDisplay='on'
                marks
                min={1}
                max={packs.length}
                step={1}
                onChange={(e, x) => { if (x !== showCount) setShowCount(x) }}
              />
            </Box>
          </Paper>
        </Box>
        {/* Results */}
        <Grid container spacing={4}>
          {_.map(topPacks, pack => (
            <Grid item key={pack.id} xs={12} sm={6} md={4} lg={2}>
              <Card className={classes.card}>
                <CardMedia
                  className={classes.cardMediaImage}
                  image={pack.image}
                  title={pack.text}
                />
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
      {/* Shuffle buttons */}
      <Shuffler list={packs} setList={setPacks} onChange={() => setShowCount(DEFAULT_SHOWCOUNT)} />
      <Container style={{ height: 150 }}><div /></Container>
    </>
  )
}

export default DJMax
