/* code taken from https://usehooks.com/useLocalStorage/ and modified */
import { useState } from 'react'
import axios from 'axios'
import _ from 'lodash'

// In contrast to 'LocalStorage', this stores key/value in backend server
function useBackendStorage (key, initialValue, loggedIn) {
  const BACKEND_STORAGE_URL = '/api/kvs'

  // If 'loggedIn' === false, then the value is initialized as 'null'
  const [storedValue, setStoredValue] = useState(null)
  const [initialized, setInitialized] = useState(!loggedIn)

  const initialize = () => {
    if (initialized) return
    // Get from backend storage by key
    axios.get(BACKEND_STORAGE_URL + '/' + encodeURIComponent(key))
      .then(res => {
        setStoredValue(JSON.parse(res.data.value))
        setInitialized(true)
      }).catch(err => {
        if (err.response && err.response.status === 400) {
          setStoredValue(initialValue)
          setInitialized(true)
        }
      })
  }

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = _.debounce(value => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value
      // Save to backend storage
      axios.post(BACKEND_STORAGE_URL + '/' + encodeURIComponent(key),
        { value: JSON.stringify(valueToStore) }
      ).then(res => {
        // Save state
        setStoredValue(valueToStore)
      })
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error)
    }
  }, 200)

  initialize()

  return [storedValue, setValue, initialized]
}

export default useBackendStorage
