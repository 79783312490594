import React, { useState, useEffect } from 'react'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardMedia from '@material-ui/core/CardMedia'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Slider from '@material-ui/core/Slider'
import _ from 'lodash'

import useStyles from 'style'
import Shuffler from 'components/features/Shuffler'
import useLocalStorage from 'hooks/useLocalStorage'

import iRespect from 'assets/images/djmax/respect.png'
import iPortable from 'assets/images/djmax/portable.jpg'
import iPortable2 from 'assets/images/djmax/portable2.jpg'
import iClazziquai from 'assets/images/djmax/clazziquai.jpg'
import iBlacksquare from 'assets/images/djmax/blacksquare.jpg'
import iTrilogy from 'assets/images/djmax/trilogy.jpg'
import iTechnika from 'assets/images/djmax/technika.jpg'
import iTechnika2 from 'assets/images/djmax/technika2.jpg'
import iTechnika3 from 'assets/images/djmax/technika3.jpg'
import iGuiltygear from 'assets/images/djmax/guiltygear.jpg'
import iFrontline from 'assets/images/djmax/frontline.jpg'
import iGroovecoaster from 'assets/images/djmax/groovecoaster.jpg'
import iDeemo from 'assets/images/djmax/deemo.jpg'

const DEFAULT_SHOWCOUNT = 5

const newPack = (image, text) => ({
  image: image,
  text: text
})

const packData = [
  newPack(iRespect, 'RESPECT'),
  newPack(iPortable, 'PORTABLE'),
  newPack(iPortable2, 'PORTABLE 2'),
  newPack(iClazziquai, 'CLAZZIQUAI EDITION'),
  newPack(iBlacksquare, 'BLACK SQUARE'),
  newPack(iTrilogy, 'TRILOGY'),
  newPack(iTechnika, 'TECHNIKA'),
  newPack(iTechnika2, 'TECHNIKA2'),
  newPack(iTechnika3, 'TECHNIKA3'),
  newPack(iGuiltygear, 'GUILTY GEAR'),
  newPack(iFrontline, 'GIRLS\' FRONTLINE'),
  newPack(iGroovecoaster, 'GROOVE COASTER'),
  newPack(iDeemo, 'DEEMO')
]

const initPacks = _.map(_.zip(packData, _.range(packData.length)), ([pack, i]) => {
  pack.id = i
  return pack
})

// TODO: if pack is added, it should be added
const checkPacks = (packs, setPacks) => {
  const initPackDict = _.keyBy(initPacks, initPack => initPack.id)

  let success = true
  _.each(packs, pack => {
    if (!pack) {
      success = false
      return
    }
    if (pack.image !== initPackDict[pack.id].image) {
      success = false
      // return
    }
  })

  if (success) return
  const newPacks = _.compact(_.map(packs, pack => {
    if (!pack) return null
    pack.image = initPackDict[pack.id].image
    return pack
  }))
  setPacks(newPacks)
}

// https://stackoverflow.com/questions/2909367/can-you-determine-if-chrome-is-in-incognito-mode-via-a-script
const checkIncognito = async () => {
  const fs = window.RequestFileSystem || window.webkitRequestFileSystem
  if (!fs) return new Promise(true)
  return new Promise(resolve =>
    fs(window.TEMPORARY, 100, () => resolve(false), () => resolve(true))
  )
}

const DJMaxPs4 = () => {
  const classes = useStyles()
  const [incognitoMode, setIncognitoMode] = useState(false)
  const [packs, setPacks] = useLocalStorage('djmaxps4.packs', initPacks)
  const [showCount, setShowCount] = useLocalStorage('djmaxps4.showCount', DEFAULT_SHOWCOUNT)

  useEffect(() => {
    // 'async' workaround for useEffect
    const asyncFn = async () => setIncognitoMode(await checkIncognito())
    asyncFn()
  }, [])

  useEffect(() => {
    checkPacks(packs, setPacks)
  }, [packs, setPacks])

  const topPacks = _.take(packs, showCount)

  return (
    <>
      {incognitoMode ? <Typography variant='h6' color='error'>시크릿 모드에서는 섞기 기록이 저장되지 않습니다.</Typography> : <></>}
      <Container className={classes.cardGrid} maxWidth='xl'>
        {/* showCount slider */}
        <Box maxWidth={350} paddingBottom={5}>
          <Paper className={classes.paper}>
            <Box className={classes.horizontalBox} width={300}>
              <Typography>Showing&nbsp;&nbsp;&nbsp;</Typography>
              <Slider
                value={showCount}
                valueLabelDisplay='on'
                marks
                min={1}
                max={packs.length}
                step={1}
                onChange={(e, x) => { if (x !== showCount) setShowCount(x) }}
              />
            </Box>
          </Paper>
        </Box>
        {/* Results */}
        <Grid container spacing={4}>
          {_.map(topPacks, pack => (
            <Grid item key={pack.id} xs={12} sm={6} md={4} lg={2}>
              <Card className={classes.card}>
                <CardMedia
                  className={classes.cardMediaImage}
                  image={pack.image}
                  title={pack.text}
                />
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
      {/* Shuffle buttons */}
      <Shuffler list={packs} setList={setPacks} onChange={() => setShowCount(DEFAULT_SHOWCOUNT)} />
      <Container style={{ height: 150 }}><div /></Container>
    </>
  )
}

export default DJMaxPs4
