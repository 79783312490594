import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import CssBaseline from '@material-ui/core/CssBaseline'

import Header from 'components/layouts/Header'
import Footer from 'components/layouts/Footer'
import Index from 'components/pages/Index'
import DJMax from 'components/pages/DJMax'
import DJMaxPs4 from 'components/pages/DJMaxPs4'
import CppTool from 'components/pages/CppTool'
import Factorio from 'components/pages/Factorio'
import { ProvideAuth } from 'hooks/useOAuth'

const AppRouter = () =>
  <ProvideAuth>
    <BrowserRouter>
      <div>
        <CssBaseline />
        <Header />
        <main>
          <Switch>
            <Route path='/' exact component={Index} />
            <Route path='/djmax' component={DJMax} />
            <Route path='/djmaxps4' component={DJMaxPs4} />
            <Route path='/cpptool' component={CppTool} />
            <Route path='/factorio' component={Factorio} />
          </Switch>
        </main>
        <Footer />
      </div>
    </BrowserRouter>
  </ProvideAuth>

export default AppRouter
