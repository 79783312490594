import React from 'react'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import _ from 'lodash'

import useStyles from 'style'
import { Box } from '@material-ui/core'

const shuffle = (list, setList, onChange, action) => {
  list = listDefaultAttr(list)
  let newList
  switch (action) {
    case 'balanced':
      // 많이 섞기
      // 과거에 나오지 않았던 item이 나올 확률 증가
      newList = shuffleBalanced(list)
      newList = updatePickAmount(newList)
      break
    case 'pure':
      // 그냥 섞기
      newList = shufflePure(list)
      newList = updatePickAmount(newList)
      break
    case 'biased':
      // 조금 섞기
      // 첫번째 item은 고정, 그 이후부터 섞일 확률 증가
      newList = shuffleBiased(list)
      break
    default:
      newList = list
      break
  }
  setList(newList)
  onChange()
}

const listDefaultAttr = list => {
  let hasDefaultAttr = true
  const newList = _.map(list, item => {
    if (!('shuffle' in item)) {
      hasDefaultAttr = false
      item.shuffle = { pickAmount: 0 }
    }
    return item
  })
  return hasDefaultAttr ? list : newList
}

const shufflePure = _.shuffle

const shuffleBiased = list => {
  const BASE_SHUFFLE_PROBABILITY = 0.2
  const newList = _.clone(list)
  _.each(_.range(1, newList.length - 1), i => {
    if (_.random(0, 1, true) <= BASE_SHUFFLE_PROBABILITY) {
      const targetIndex = _.random(i + 1, newList.length - 1, false)
      const target = newList[targetIndex]
      const item = newList[i]
      newList[targetIndex] = item
      newList[i] = target
    }
  })
  return newList
}

const shuffleBalanced = list => {
  const sorted = _.sortBy(list, item => item.shuffle.pickAmount)
  const pickAmounts = _.map(sorted, item => item.shuffle.pickAmount)

  // Minimum weight = 25, maximum weight = 175
  const weights = _.map(normalizeSortedList(pickAmounts), p => 25 + (150 * p))
  const shufflePool = _.flatMap(_.zip(sorted, weights), ([item, w]) => {
    return _.map(_.times(w), () => item)
  })

  const shuffleFn = (shufflePool, picked) => {
    if (shufflePool.length === 0) return picked
    const i = _.random(0, shufflePool.length - 1, false)
    const item = shufflePool[i]
    const pulled = _.without(shufflePool, item)
    const newPicked = [...picked, item]
    return shuffleFn(pulled, newPicked)
  }
  return shuffleFn(shufflePool, [])
}

// 1st pick += n^2, 2nd pick += (n-1)^2, ...
const updatePickAmount = list => _.map(_.zip(list, _.range(list.length, 0)), ([item, i]) => {
  item.shuffle.pickAmount += i * i
  return item
})

// Minimum value = 0, maximum value = 1
const normalizeSortedList = sortedList => {
  const minValue = _.min(sortedList)
  const maxValue = _.max(sortedList)
  if (maxValue === 0) return sortedList
  return _.map(sortedList, x => (x - minValue) / (maxValue - minValue))
}

const Shuffler = ({ list, setList, onChange }) => {
  const classes = useStyles()
  const shuffleReducer = _.partial(shuffle, list, setList, onChange)
  return (
    <>
      <Container maxWidth='xl'>
        <Box className={classes.horizontalBox} justifyContent='center'>
          <Button className={classes.icon} variant='contained' color='secondary' onClick={() => shuffleReducer('balanced')}>많이 섞기</Button>
          <Button className={classes.icon} variant='contained' color='primary' onClick={() => shuffleReducer('pure')}>그냥 섞기</Button>
          <Button className={classes.icon} variant='contained' color='default' onClick={() => shuffleReducer('biased')}>조금 섞기</Button>
        </Box>
      </Container>
    </>
  )
}

export default Shuffler
