import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar'
import Grid from '@material-ui/core/Grid'
import HomeIcon from '@material-ui/icons/Home'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import _ from 'lodash'

import { useOAuth } from 'hooks/useOAuth'
import { useHover } from 'hooks/useHover'

const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: theme.spacing(2)
  }
}))

const Header = () => {
  const classes = useStyles()
  const [menuAnchorEl, setMenuAnchorEl] = useState(null)
  const [redirect, setRedirect] = useState(null)
  const [signOutHoverRef, signOutHovered] = useHover()
  const auth = useOAuth()
  const signIn = auth.signIn
  const signOut = auth.signOut

  const anchorMenu = e => setMenuAnchorEl(e.currentTarget)
  const unanchorMenu = () => setMenuAnchorEl(null)

  // If 'redirect' is same as current path, don't do anything
  // (in consistency with 'react-router' logic)
  useEffect(() => {
    if (window.location.pathname === redirect) setRedirect(null)
  }, [redirect])

  if (redirect !== null) {
    return <><Redirect to={redirect} /></>
  }

  return (
    <>
      <AppBar position='relative'>
        <Toolbar>
          <div
            style={{ display: 'flex', cursor: 'pointer' }}
            onClick={() => setRedirect('/')}
          >
            <HomeIcon className={classes.icon} style={{ color: '#ffffff' }} />
            <Typography variant='h6' color='inherit' noWrap>
              Sonowz Sandbox
            </Typography>
          </div>
          <Grid container justify='flex-end'>
            {auth.user
              ? (
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={signOut}
                  ref={signOutHoverRef}
                >
                  {signOutHovered
                    ? <Typography variant='h6' color='inherit' noWrap>Sign Out</Typography>
                    : auth.user.email}
                </div>
                )
              : (
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={signIn}
                >
                  <Typography variant='h6' color='inherit' noWrap>Sign In</Typography>
                </div>
                )}
            <div
              className={classes.clickPointer}
              style={{ width: '160px', marginLeft: '20px' }}
              aria-controls='menu'
              aria-haspopup='true'
              onClick={anchorMenu}
              onMouseEnter={anchorMenu}
            >
              <Typography variant='h6' color='inherit' align='right' noWrap>
                Other Pages
              </Typography>
            </div>
          </Grid>
          <Menu
            id='menu'
            anchorEl={menuAnchorEl}
            open={!_.isNil(menuAnchorEl)}
            keepMounted
            onClose={unanchorMenu}
          >
            <MenuItem onClick={() => { window.location.href = '/jobs' }}>Job Archiver</MenuItem>
            <MenuItem onClick={() => { unanchorMenu(); setRedirect('/djmax') }}>DJMAX Picker</MenuItem>
            <MenuItem onClick={() => { window.location.href = '/factorio' }}>Factorio Timelapses</MenuItem>
            <MenuItem onClick={() => { window.location.href = '/raytrace' }}>Raytrace Demo</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    </>
  )
}

export default Header
