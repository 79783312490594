/* code taken from https://usehooks.com/useAuth/, and modified it */
import React, { useState, useContext, createContext } from 'react'
import axios from 'axios'

const authContext = createContext()
// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth ({ children }) {
  const auth = useProvideAuth()
  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}
// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useOAuth = () => {
  return useContext(authContext)
}
// Provider hook that creates auth object and handles state
function useProvideAuth () {
  const LOGIN_URL = '/api/auth'
  const LOGOUT_URL = '/api/logout'
  const LOGIN_CHECK_URL = '/api/user'
  const [user, setUser] = useState(null)

  const updateUser = () => {
    if (user) return
    axios.get(LOGIN_CHECK_URL)
      .then(res => {
        setUser({ email: res.data.username })
      }).catch(err => {
        if (err.response && err.response.status === 401) return
        console.error(err)
      })
  }
  const signIn = () => {
    window.location.href = LOGIN_URL
  }
  const signOut = () => {
    axios.post(LOGOUT_URL)
      .finally(() => setUser(null))
  }

  // OAuth request changes URL to Google domain and then redirects to the blog domain,
  // therefore the page gets refreshed when OAuth request chain is finished
  updateUser()

  // Return the user object and auth methods
  return {
    user,
    signIn,
    signOut
  }
}
