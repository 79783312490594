/* Template from material-ui */
import React from 'react'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
// import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects'
import LiveTvIcon from '@material-ui/icons/LiveTv'
import SportsEsportsIcon from '@material-ui/icons/SportsEsports'
import WorkIcon from '@material-ui/icons/Work'

import iJobArchiver from 'assets/images/thumbnail/jobarchiver.png'
import iDJMax from 'assets/images/thumbnail/djmax.jpg'
import iFactorio from 'assets/images/thumbnail/factorio-seablock.png'
import iRaytrace from 'assets/images/thumbnail/raytrace.png'

import useStyles from 'style'

const Index = () => {
  const classes = useStyles()

  return (
    <>
      {/* Hero unit */}
      <div className={classes.heroContent}>
        <Container maxWidth='m'>
          <Typography component='h1' variant='h2' align='center' color='textPrimary' gutterBottom>
            Personal Sandbox Page
          </Typography>
          <div className={classes.heroButtons}>
            <Grid container spacing={2} justify='center'>
              <Grid item>
                <Button variant='contained' color='primary' component='a' href='https://www.github.com/sonowz'>
                  Github Profile
                </Button>
              </Grid>
              {/*
              <Grid item>
                <Button variant='outlined' color='primary'>
                  Secondary action
                </Button>
              </Grid>
              */}
            </Grid>
          </div>
        </Container>
      </div>
      <Container className={classes.cardGrid} maxWidth='md'>
        {/* End hero unit */}
        <Grid container spacing={4}>
          <Grid item key='jobarchiver' xs={12} sm={6} md={4}>
            <a href='/jobs' className={classes.noTextDecoration}>
              <Card className={classes.card}>
                <CardMedia
                  className={classes.cardMedia}
                  image={iJobArchiver}
                  title='Image title'
                />
                <CardContent className={classes.cardContent}>
                  <Typography gutterBottom variant='h5' component='h2'>
                    Job Archiver
                    <WorkIcon />
                  </Typography>
                  <Typography>
                    네이버/카카오 구인 아카이브
                  </Typography>
                </CardContent>
              </Card>
            </a>
          </Grid>
          {/* <Grid item key='cpptool' xs={12} sm={6} md={4}>
            <Link to='/cpptool' className={classes.noTextDecoration}>
              <Card className={classes.card}>
                <CardMedia
                  className={classes.cardMedia}
                  image={null}
                  title='Image title'
                />
                <CardContent className={classes.cardContent}>
                  <Typography gutterBottom variant='h5' component='h2'>
                    CopyProductivePaste Tool
                    <EmojiObjectsIcon />
                  </Typography>
                  <Typography>
                    복사 붙여넣기 툴
                  </Typography>
                </CardContent>
              </Card>
            </Link>
          </Grid> */}
          <Grid item key='djmax' xs={12} sm={6} md={4}>
            <Link to='/djmax' className={classes.noTextDecoration}>
              <Card className={classes.card}>
                <CardMedia
                  className={classes.cardMedia}
                  image={iDJMax}
                  title='Image title'
                />
                <CardContent className={classes.cardContent}>
                  <Typography gutterBottom variant='h5' component='h2'>
                    DJMAX Picker
                    <SportsEsportsIcon />
                  </Typography>
                  <Typography>
                    DJMAX 뮤직팩 랜덤선택
                  </Typography>
                </CardContent>
              </Card>
            </Link>
          </Grid>
          <Grid item key='factorio-seablock' xs={12} sm={6} md={4}>
            <Link to='/factorio' className={classes.noTextDecoration}>
              <Card className={classes.card}>
                <CardMedia
                  className={classes.cardMedia}
                  image={iFactorio}
                  title='Image title'
                />
                <CardContent className={classes.cardContent}>
                  <Typography gutterBottom variant='h5' component='h2'>
                    Factorio Session Timelapses
                    <SportsEsportsIcon />
                  </Typography>
                  <Typography>
                    팩토리오 세션 타임랩스 모음
                  </Typography>
                </CardContent>
              </Card>
            </Link>
          </Grid>
          <Grid item key='raytrace' xs={12} sm={6} md={4}>
            <a href='/raytrace' className={classes.noTextDecoration}>
              <Card className={classes.card}>
                <CardMedia
                  className={classes.cardMedia}
                  image={iRaytrace}
                  title='Image title'
                />
                <CardContent className={classes.cardContent}>
                  <Typography gutterBottom variant='h5' component='h2'>
                    Raytrace Demo
                    <LiveTvIcon />
                  </Typography>
                  <Typography>
                    컴퓨터그래픽스 강의 최종과제 Ray Tracing 시연
                  </Typography>
                </CardContent>
              </Card>
            </a>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default Index
