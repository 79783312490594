import React, { useState } from 'react'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
// import _ from 'lodash'

// import useStyles from 'style'

const CppTool = () => {
  // const classes = useStyles()
  const [, setInputCode] = useState('')

  return (
    <>
      <Container maxWidth='lg' style={{ height: '70vh' }}>
        <Grid container spacing={4}>
          <Grid item key='input' xs={8} md={9}>
            <div style={{ height: '35vh' }}>
              <TextField
                label='Input Code'
                multiline
                rows='20'
                variant='outlined'
                InputProps={{
                  style: { fontFamily: 'monospace', height: '30vh' }
                }}
                fullWidth
                margin='normal'
                onChange={e => setInputCode(e.target.value)}
              />
            </div>
            <div style={{ height: '35vh' }}>
              <TextField
                label='Output Code'
                multiline
                rows='20'
                variant='outlined'
                InputProps={{
                  style: { fontFamily: 'monospace', height: '30vh' }
                }}
                fullWidth
                margin='normal'
                onChange={e => setInputCode(e.target.value)}
              />
            </div>
          </Grid>
          <Grid item key='input' xs={4} md={3}>
            <div style={{ height: '70vh' }}>
              <TextField
                label='Input List'
                multiline
                rows='20'
                variant='outlined'
                InputProps={{
                  style: { fontFamily: 'monospace', height: '65vh' }
                }}
                fullWidth
                margin='normal'
                onChange={e => setInputCode(e.target.value)}
              />
            </div>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default CppTool
